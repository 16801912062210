import React, {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import Link from "next/link";

import DropdownIcon from "./Icons/DropdownIcon";
import Transition from "./Transition";

export const DropdownMenuItem = ({
  href,
  onClick,
  icon,
  text,
  variant = "default",
  isFirst,
  isLast,
  setIsOpen,
  closeDropdownOnClick,
  className,
  textClassName,
  heapEventName,
  modal,
}) => {
  const Component = onClick || modal ? "button" : href ? "a" : "span";

  const handleClick = (e) => {
    onClick && onClick(e);
    if (closeDropdownOnClick) setIsOpen(false);
  };

  const item = (
    <Component
      onClick={handleClick}
      data-heap-event-name={heapEventName}
      className={classNames(
        "p-5 transition ease-in-out duration-150 flex border-b border-black-ink/10 focus:outline-none cursor-pointer",
        className,
        isFirst && "rounded-t-md border-b border-black-ink/10",
        isLast && "rounded-b-md",
        variant === "primary" && "hover:bg-action-600 active:bg-action-500",
        (variant === "default" || variant === "ghost-theme") &&
          "hover:bg-grey-900 active:bg-grey-800",
        variant === "white" && "hover:bg-action-600 active:bg-action-500"
      )}
    >
      <span className="w-6 flex items-center flex-none justify-center">
        {icon}
      </span>
      <p className={classNames("text-black-ink ml-3", textClassName)}>{text}</p>
    </Component>
  );

  if (onClick) return item;
  if (href)
    return (
      <Link href={href} legacyBehavior>
        {item}
      </Link>
    );
  if (modal)
    return cloneElement(modal, {
      trigger: item,
    });
  return item;
};

export const DropdownMenu = ({
  id,
  open,
  setOpen,
  icon,
  text,
  variant = "default",
  className,
  children,
  size = "large",
  positionX = "right",
  positionY = "bottom",
  margin = {},
  shadowClass,
  buttonShape = "circle",
}) => {
  const [isOpen, setIsOpen] = useState(!!open);
  const ref = useRef();

  const openState = open ?? isOpen;
  const setOpenState = setOpen ?? setIsOpen;

  const toggleOpen = useCallback(
    (e) => setOpenState(ref.current?.contains(e.target)),
    [setOpenState]
  );

  useEffect(() => {
    document.addEventListener("mousedown", toggleOpen);
    return () => document.removeEventListener("mousedown", toggleOpen);
  }, [toggleOpen]);

  return (
    <div ref={ref} id={id} className={classNames("relative", className)}>
      {(icon || text) && (
        <button
          onClick={toggleOpen}
          className={classNames(
            "inline-flex items-center justify-center h-12 text-black-ink focus:outline-none disabled:text-grey-800 disabled:bg-grey-950 disabled:cursor-not-allowed transition duration-150 ease-in-out",
            {
              "bg-action-700 hover:bg-action-600 focus:bg-action-500 active:bg-action-500":
                variant === "primary",
              "bg-grey-950 hover:bg-action-900 focus:bg-action-700 active:bg-action-700":
                variant === "default",
              "bg-white hover:bg-action-900 focus:bg-action-700 active:bg-action-700":
                variant === "white",
              "text-foreground disabled:text-foreground/20 bg-foreground/7 hover:bg-foreground/20 active:bg-accent/40  disabled:bg-background/5":
                variant === "ghost-theme",
              "rounded-full": buttonShape === "circle",
              "rounded-lg": buttonShape === "square",
              "rounded-lg sm:rounded-full": buttonShape === "square-on-mobile",
              "w-12": !!icon && !text,
              "w-max px-5 font-medium": !!text,
            }
          )}
        >
          {icon}
          {text && (
            <>
              {text}
              <DropdownIcon className="w-8 -ml-0.5 -mr-2 mb-0.5" />
            </>
          )}
        </button>
      )}

      <Transition
        show={openState}
        className={classNames(
          "absolute z-50 bg-transparent transform w-screen rounded-lg",
          positionX === "right" ? "left-full -translate-x-full" : "left-0",
          positionY === "bottom"
            ? "mt-3"
            : `${margin.mb ?? "mb-3"} bottom-full`,
          size === "large" && "max-w-xs",
          size === "medium" && "max-w-xxs",
          size === "small" && "max-w-xxxs"
        )}
      >
        <div className={classNames("rounded-lg bg-transparent", shadowClass)}>
          <div className="rounded-lg bg-transparent overflow-hidden">
            <div
              className={classNames(
                "z-20 relative grid gap-0 overflow-y-auto max-h-[450px] xs:max-h-full",
                variant === "primary" && "bg-action-700",
                (variant === "default" || variant === "ghost-theme") &&
                  "bg-grey-950",
                variant === "white" && "bg-white"
              )}
            >
              {React.Children.map(
                children,
                (child, index) =>
                  React.isValidElement(child) &&
                  React.cloneElement(child, {
                    variant,
                    setIsOpen: setOpenState,
                    isFirst: index === 0,
                    isLast: index === children.length - 1,
                  })
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
