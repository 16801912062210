import { FC } from "react";
import Link from "next/link";

import { Button } from "@components/Button";
import { ActionButtonProps } from "@components/Buttons/ActionButton";
import { DropdownMenu, DropdownMenuItem } from "@components/DropdownMenu";
import MoreIcon from "@components/Icons/MoreIcon";

export type ClientHeaderButtonType = ActionButtonProps;

interface Props {
  headerButtons: ClientHeaderButtonType[];
}

const ClientHeaderButtons: FC<Props> = ({ headerButtons }) => {
  if (!headerButtons?.length) return null;

  const buttonShape = "square";

  const renderOptions = (key: string) => {
    return headerButtons?.reduce((previous: JSX.Element[], current, index) => {
      const button = current as ActionButtonProps;

      const content = (
        <Button
          key={`header-button-key-${key}-${index}`}
          className="hidden sm:flex"
          onClick={button.onClick}
          variant={button.variant}
          data-heap-event-name={button.heapEventName}
          square={button.isIconButton}
        >
          {button.isIconButton ? button.icon : button.text}
        </Button>
      );

      previous.push(
        <>
          {button.href ? (
            <Link href={button.href} legacyBehavior>
              {content}
            </Link>
          ) : (
            <>{content}</>
          )}

          <DropdownMenuItem
            className="text-black-ink sm:hidden"
            key={`header-dropdown-option-key-${key}-${index}`}
            {...button}
          />
        </>
      );
      return previous;
    }, []);
  };

  return (
    <>
      <DropdownMenu
        className="sm:hidden"
        icon={<MoreIcon />}
        size="medium"
        shadowClass="shadow-lg"
        buttonShape={buttonShape}
        variant="ghost-theme"
      >
        {renderOptions("ddm")}
      </DropdownMenu>

      <div className="hidden sm:flex sm:gap-4">{renderOptions("buttons")}</div>
    </>
  );
};

export default ClientHeaderButtons;
