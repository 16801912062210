import usePersistentState from "@hooks/use-persistent-state";
import { MOBILE_SESSION_KEY } from "@lib/consts";

export default function useIsMobileWebView() {
  const { persistentValue: mobileSession } = usePersistentState(
    MOBILE_SESSION_KEY,
    false,
    true
  );

  return mobileSession;
}
