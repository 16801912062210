import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 12C13.2614 12 15.5 9.76142 15.5 7C15.5 4.23858 13.2614 2 10.5 2C7.73858 2 5.5 4.23858 5.5 7C5.5 9.76142 7.73858 12 10.5 12ZM10.5 10C12.1569 10 13.5 8.65685 13.5 7C13.5 5.34315 12.1569 4 10.5 4C8.84315 4 7.5 5.34315 7.5 7C7.5 8.65685 8.84315 10 10.5 10ZM4.5 17C4.5 15.8954 5.39543 15 6.5 15H11.707C11.5722 15.4768 11.5 15.98 11.5 16.5C11.5 19.5376 13.9624 22 17 22C20.0376 22 22.5 19.5376 22.5 16.5C22.5 13.4624 20.0376 11 17 11C15.2921 11 13.766 11.7785 12.7572 13H6.5C4.29086 13 2.5 14.7909 2.5 17V18.5C2.5 18.7761 2.72386 19 3 19H4C4.27614 19 4.5 18.7761 4.5 18.5V17ZM15.6662 16.9907C15.8614 17.186 16.178 17.186 16.3733 16.9908L18.9399 14.4242C19.1351 14.2289 19.4517 14.2289 19.647 14.4242L19.9088 14.686C20.1041 14.8812 20.1041 15.1978 19.9088 15.3931L16.3733 18.9286C16.178 19.1239 15.8614 19.1239 15.6662 18.9286L13.8536 17.116C13.6583 16.9208 13.6583 16.6042 13.8536 16.4089L14.1154 16.1471C14.3106 15.9518 14.6272 15.9518 14.8225 16.1471L15.6662 16.9907Z"
      />
    </svg>
  );
};

export default Icon;
