import { FC } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

interface MetaHeadProps {
  title?: string;
  subTitle?: string;
  titleContainsPractice?: boolean;
  description?: string;
  image?: string;
  robots?: string;
  children?: string;
}

const MetaHead: FC<MetaHeadProps> = ({
  title,
  subTitle,
  titleContainsPractice = true,
  description,
  image,
  robots,
  children,
}) => {
  const router = useRouter();
  const ogUrl = `https://app.practice.do${router.asPath}`;
  let metaTitle =
    title && subTitle
      ? `${title} • ${subTitle}`
      : title || subTitle
      ? `${title || subTitle}`
      : null;
  if (titleContainsPractice) {
    metaTitle = metaTitle !== null ? `${metaTitle} • Practice` : `Practice`;
  }
  const metaDescription =
    description ||
    "Sell your expertise online, and manage your business, all in one place.";
  const defaultImage = "https://practice.do/images/practice_ogimage.png";
  const metaImage = image ? image : defaultImage;
  const metaRobots = robots ? robots : "all";

  return (
    <Head>
      <title>{metaTitle}</title>
      <link href={`https://app.practice.do${router.asPath}`} rel="canonical" />
      <meta name="robots" content={metaRobots} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={metaTitle} key="title" />
      <meta property="og:image" content={metaImage} key="image" />
      <meta
        property="og:image:alt"
        content="The practice logo and wordmark on a black background."
      />
      <meta
        property="og:description"
        name="description"
        content={metaDescription}
        key="description"
      />
      <meta name="twitter:title" content={metaTitle} key="twitter-title" />
      <meta
        name="twitter:description"
        content={metaDescription}
        key="twitter-description"
      />
      <meta name="twitter:image" content={metaImage} key="twitter-image" />
      <meta name="twitter:card" content="summary_large_image" />
      {children}
    </Head>
  );
};

export default MetaHead;
