import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DropdownIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.433 15.25C12.2406 15.5833 11.7594 15.5833 11.567 15.25L8.96891 10.75C8.77646 10.4167 9.01702 10 9.40192 10L14.5981 10C14.983 10 15.2235 10.4167 15.0311 10.75L12.433 15.25Z" />
    </svg>
  );
};

export default DropdownIcon;
