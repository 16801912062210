import React, { FC, forwardRef } from "react";
import { Transition as TransitionReact } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

const Transition: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  return (
    <TransitionReact
      ref={ref}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      className={twMerge("z-50 transform w-full left", className)}
      {...rest}
    />
  );
});

export default Transition;
